import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import { InlineLoading } from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';

import AssignmentTasksCalendarTimeline from 'web/components/AssignmentTasksCalendarTimeline';

import {
	COMPANY_HAS_TASKS,
	GET_TASKS,
} from 'api/queries/companyAssignmentTaskQueries';

const Header = styled.div`
	display: flex;
	gap: 20px;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;
`;

const Title = styled.h1`
	margin: 0;
`;

export default function AssignmentTasksTab({ company }) {
	const {
		data: { companyHasTasks } = { companyHasTasks: false },
		loading,
		error,
	} = useQuery(COMPANY_HAS_TASKS, {
		variables: { companyId: company._id },
	});

	if (loading) return <InlineLoading />;

	if (error) return <ErrorMessage errors={[error]} />;

	if (!companyHasTasks) {
		return <NoCompanyTasksMessage companyId={company._id} />;
	}

	return <CompanyTasks companyId={company._id} />;
}

function NoCompanyTasksMessage({ companyId }) {
	const navigate = useNavigate();

	return (
		<Header>
			<div>
				<Title>Oppdragsutførelse</Title>

				<p>
					Det er ikke planlagt noen oppgaver for denne bedriften ennå.
				</p>
			</div>

			<Button
				type="button"
				variant="contained"
				startIcon={<AddIcon />}
				onClick={() => navigate(`/bedrifter/${companyId}/oppdrag/ny`)}
			>
				Planlegg oppgaver
			</Button>
		</Header>
	);
}

function CompanyTasks({ companyId }) {
	const [showOnlyOverdue, setShowOnlyOverdue] = useState(false);

	const {
		data: { tasks } = { tasks: [] },
		loading,
		error,
	} = useQuery(GET_TASKS, {
		variables: {
			companyId,
			onlyActive: true,
			onlyOverdue: showOnlyOverdue,
		},
	});

	if (loading) return <InlineLoading />;

	if (error) return <ErrorMessage errors={[error]} />;

	// Group all tasks by category
	const tasksGroupedByCategory = tasks
		.reduce((categories, task) => {
			let categoryGroup = categories.find(
				group => group.category.name === task.category.name
			);

			if (!categoryGroup) {
				categoryGroup = {
					category: task.category,
					tasks: [],
				};

				categories.push(categoryGroup);
			}

			categoryGroup.tasks.push(task);

			return categories;
		}, [])
		.sort((a, b) => a.category.order - b.category.order);

	return (
		<AssignmentTasksCalendarTimeline
			companyId={companyId}
			tasksGroupedByCategory={tasksGroupedByCategory}
			showOnlyOverdue={showOnlyOverdue}
			setShowOnlyOverdue={setShowOnlyOverdue}
		/>
	);
}
