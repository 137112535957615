import { useState, useRef, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import { formatDate } from 'utils/dates';
import { assigmentTaskDeadlineEntryStatusColor } from 'utils/colors';
import { pluralize } from 'utils/strings';

import EditAssignmentEntryDialog from 'web/screens/dialogs/assignmentTaskEntry/EditAssignmentEntryDialog';

import { GET_TASK_DEADLINE_ENTRIES } from 'api/queries/companyAssignmentTaskQueries';

const now = new Date();
const currentYear = now.getFullYear();
const currentMonth = now.getMonth();
const monthsInYear = 12;
const months = Array.from({ length: monthsInYear }, (_, i) => {
	const date = new Date(2000, i); // Year 2000 is arbitrary, only the month matters

	return {
		long: date.toLocaleString('no', { month: 'long' }),
		short: date.toLocaleString('no', { month: 'short' }),
	};
});

const columnWidth = 80;
const totalMonths = 3 * 12; // 3 years
const currenMonthScrollPosition =
	(monthsInYear + currentMonth - 3) * columnWidth; // 3 month before current month
const contentWidth = totalMonths * columnWidth;
const assignmentSidebarWidth = 320;

const CalendarTimelineHeader = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 15px;
	margin-bottom: 15px;
`;

const CalendarTimelineWrapper = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	width: ${contentWidth + assignmentSidebarWidth}px;
`;

const TimelineHeader = styled.div`
	background-color: ${p => p.theme.palette.common.gray};
	border-bottom: 1px solid ${p => p.theme.palette.common.gray};
	position: sticky;
	order: 2;
	width: ${contentWidth}px;
	top: 0;
	z-index: 1;
`;

const TimelineSidebarSpacer = styled.div`
	position: sticky;
	order: 1;
	left: 0;
	top: 0;
	width: ${assignmentSidebarWidth}px;
	height: 80px;
	z-index: 3;
	background-color: ${p => p.theme.palette.background.gray};
`;

const TimelineAssignmentSidebar = styled.div`
	position: sticky;
	order: 3;
	left: 0;
	background-color: ${p => p.theme.palette.background.paper};
	width: ${assignmentSidebarWidth}px;
	z-index: 2;
`;

const TimelineContent = styled.div`
	background-color: ${p => p.theme.palette.common.gray};
	border: 1px solid ${p => p.theme.palette.common.gray};
	border-left: none;
	border-top: none;
	order: 4;
	position: relative;
	width: ${contentWidth}px;
`;

const TaskRow = styled.div`
	background: ${p => p.theme.palette.common.gray};
	border-right: 1px solid ${p => p.theme.palette.common.gray};
	flex-shrink: 0;
	display: grid;
	gap: 1px;
	grid-template-columns: 1fr;
	margin-bottom: 15px;
`;

const Cell = styled.div`
	background-color: ${p =>
		p.$current
			? p => p.theme.palette.background.lightGrayBlue
			: p.theme.palette.background.default};
	display: flex;
	align-items: center;
	gap: 2px;
	height: 40px;
`;

const TaskCell = styled(Cell)`
	padding: 10px 20px;
`;

const CategoryName = styled.div`
	font-size: 14px;
	font-weight: bold;
`;

const TaskName = styled.div`
	font-size: 14px;
	line-height: 1;
	padding-left: 20px;
`;

const TimelineGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(36, 80px);
	gap: 1px;
`;

const YearRow = styled.div`
	line-height: 1;
	display: grid;
	grid-template-columns: repeat(36, 80px);
	gap: 1px;
	margin-bottom: 1px;
`;

const YearCell = styled(Cell)`
	background: ${p => p.theme.palette.background.default};
	font-size: 12px;
	letter-spacing: 1px;
	justify-content: center;
	text-transform: uppercase;
	grid-column: span 12;

	font-weight: ${({ $current }) => ($current ? 'bold' : 'normal')};
`;

const MonthRow = styled.div`
	line-height: 1;
	display: grid;
	grid-template-columns: repeat(36, 80px);
	gap: 1px;
`;

const MonthCell = styled(Cell)`
	font-size: 12px;
	letter-spacing: 1px;
	justify-content: center;
	text-transform: uppercase;

	font-weight: ${({ $current }) => ($current ? 'bold' : 'normal')};
`;

const GroupCell = styled(Cell)`
	grid-column: 1 / -1;
	background: ${p => p.theme.palette.background.gray};
`;

export default function AssignmentTasksCalendarTimeline({
	companyId,
	tasksGroupedByCategory,
	showOnlyOverdue,
	setShowOnlyOverdue,
}) {
	const [selectedYear, setSelectedYear] = useState(currentYear);
	const [scrollPosition, setScrollPosition] = useState({
		x: currenMonthScrollPosition,
		y: 0,
	});

	const years = [selectedYear - 1, selectedYear, selectedYear + 1];

	return (
		<div>
			<CalendarTimelineHeader>
				<TimelineYearSelect
					selectedYear={selectedYear}
					setSelectedYear={setSelectedYear}
					setScrollPosition={setScrollPosition}
				/>

				<Button
					variant="outlined"
					size="small"
					onClick={() => setShowOnlyOverdue(prevState => !prevState)}
				>
					{showOnlyOverdue ? 'Vis alle' : 'Vis forfalte'}
				</Button>

				<Button
					variant="outlined"
					size="small"
					startIcon={<EditIcon />}
					component={Link}
					to={`/bedrifter/${companyId}/oppdrag/planlegg/`}
				>
					Rediger oppgaver
				</Button>
			</CalendarTimelineHeader>

			{tasksGroupedByCategory.length === 0 ? (
				<EmptyCalendarTimeline
					companyId={companyId}
					showOnlyOverdue={showOnlyOverdue}
					setShowOnlyOverdue={setShowOnlyOverdue}
				/>
			) : (
				<CalendarTimeline
					scrollPosition={scrollPosition}
					tasksGroupedByCategory={tasksGroupedByCategory}
					years={years}
					showOnlyOverdue={showOnlyOverdue}
				/>
			)}
		</div>
	);
}

const TimelineYearSelectWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const SelectedYearButton = styled(Button)`
	color: ${p => p.theme.palette.text.primary};
	font-weight: bold;
	font-size: 18px;
	letter-spacing: 1px;
	min-width: auto;
	padding: 0;

	:disabled {
		color: ${p => p.theme.palette.text.primary};
	}
`;

function TimelineYearSelect({
	selectedYear,
	setSelectedYear,
	setScrollPosition,
}) {
	return (
		<TimelineYearSelectWrapper>
			<IconButton
				type="button"
				aria-label="Forrige år"
				title="Forrige år"
				size="small"
				onClick={() => {
					setSelectedYear(selectedYear - 1);
					setScrollPosition({
						x: 0,
						y: 0,
					});
				}}
			>
				<ChevronLeftIcon fontSize="inherit" />
			</IconButton>

			<SelectedYearButton
				title="Gå til gjeldende måned"
				size="small"
				onClick={() => {
					setSelectedYear(currentYear);
					setScrollPosition({
						x: currenMonthScrollPosition,
						y: 0,
					});
				}}
			>
				{selectedYear - 1} – {selectedYear + 1}
			</SelectedYearButton>

			<IconButton
				aria-label="Neste år"
				title="Neste år"
				size="small"
				disabled={selectedYear >= currentYear}
				onClick={() => {
					setSelectedYear(selectedYear + 1);
					setScrollPosition({
						x: 0,
						y: 0,
					});
				}}
			>
				<ChevronRightIcon fontSize="inherit" />
			</IconButton>
		</TimelineYearSelectWrapper>
	);
}

const EmptyCalendarTimelineWrapper = styled.div`
	text-align: center;
	padding: 20px; 0;
`;

const EmptyCalendarTimelineMessage = styled.div`
	font-style: italic;
	margin-bottom: 10px;
`;

function EmptyCalendarTimeline({
	companyId,
	showOnlyOverdue,
	setShowOnlyOverdue,
}) {
	if (showOnlyOverdue) {
		return (
			<EmptyCalendarTimelineWrapper>
				<EmptyCalendarTimelineMessage>
					Ingen forfalte oppgaver
				</EmptyCalendarTimelineMessage>

				<Button
					variant="outlined"
					onClick={() => setShowOnlyOverdue(false)}
				>
					Vis alle
				</Button>
			</EmptyCalendarTimelineWrapper>
		);
	}

	return (
		<EmptyCalendarTimelineWrapper>
			<EmptyCalendarTimelineMessage>
				Ingen planlagte oppgaver
			</EmptyCalendarTimelineMessage>

			<Button
				variant="outlined"
				size="small"
				startIcon={<EditIcon />}
				component={Link}
				to={`/bedrifter/${companyId}/oppdrag/planlegg/`}
			>
				Planlegg
			</Button>
		</EmptyCalendarTimelineWrapper>
	);
}

function CalendarTimeline({
	scrollPosition,
	tasksGroupedByCategory,
	years,
	showOnlyOverdue,
}) {
	return (
		<ScrollContainer jumpToPosition={scrollPosition}>
			<CalendarTimelineWrapper>
				<TimelineSidebarSpacer />

				<TimelineAssignmentSidebar>
					<TaskRow>
						{tasksGroupedByCategory.map(
							(categoryGroup, assignmentIndex) => (
								<Fragment key={assignmentIndex}>
									<TaskCell>
										<CategoryName>
											{categoryGroup.category.name}
										</CategoryName>
									</TaskCell>

									{categoryGroup.tasks.map(
										(task, taskIndex) => (
											<TaskCell key={taskIndex}>
												<TaskName>{task.name}</TaskName>
											</TaskCell>
										)
									)}
								</Fragment>
							)
						)}
					</TaskRow>
				</TimelineAssignmentSidebar>

				<TimelineHeader>
					<YearRow>
						{years.map(year => (
							<YearCell
								key={year}
								$current={year === currentYear}
							>
								{year}
							</YearCell>
						))}
					</YearRow>

					<MonthRow>
						{years.map(year =>
							months.map((month, monthIndex) => (
								<MonthCell
									key={`${year}-${month.short}`}
									$current={
										year === currentYear &&
										monthIndex === currentMonth
									}
									title={`${month.long} ${year}`}
								>
									{month.short}
								</MonthCell>
							))
						)}
					</MonthRow>
				</TimelineHeader>

				<TimelineContent>
					<TimelineGrid>
						{tasksGroupedByCategory.map(
							(category, assignmentIndex) => (
								<Fragment key={assignmentIndex}>
									<GroupCell> </GroupCell>

									{category.tasks.map(task => (
										<TaskDeadlines
											key={task._id}
											years={years}
											taskId={task._id}
											showOnlyOverdue={showOnlyOverdue}
										/>
									))}
								</Fragment>
							)
						)}
					</TimelineGrid>
				</TimelineContent>
			</CalendarTimelineWrapper>
		</ScrollContainer>
	);
}

const ScrollableElement = styled.div`
	cursor: ${p => (p.$isGrabbing ? 'grabbing' : 'grab')};
	border: 1px solid ${p => p.theme.palette.background.paper};
	overflow: auto;
	height: 85vh;
	width: 100%;
	user-select: none;
`;

function hasOverflowHidden(element) {
	const computedStyle = window.getComputedStyle(element);
	return computedStyle.overflow === 'hidden';
}

function ScrollContainer({ jumpToPosition = null, children }) {
	const containerRef = useRef(null);
	const [isGrabbing, setIsGrabbing] = useState(false);
	const [position, setPosition] = useState({ x: 0, y: 0 });

	const handleMouseDown = e => {
		if (hasOverflowHidden(document.body)) return;

		setIsGrabbing(true);
		setPosition({ x: e.clientX, y: e.clientY });
	};

	const handleMouseMove = e => {
		if (!isGrabbing || hasOverflowHidden(document.body)) return;

		const deltaX = e.clientX - position.x;
		const deltaY = e.clientY - position.y;

		if (containerRef.current) {
			containerRef.current.scrollLeft -= deltaX;
			containerRef.current.scrollTop -= deltaY;
		}

		setPosition({ x: e.clientX, y: e.clientY });
	};

	const handleMouseUp = () => {
		setIsGrabbing(false);
	};

	useEffect(() => {
		if (!jumpToPosition) return;

		containerRef.current.scrollLeft = jumpToPosition.x;
		containerRef.current.scrollTop = jumpToPosition.y;
	}, [jumpToPosition]);

	return (
		<ScrollableElement
			ref={containerRef}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseUp}
			$isGrabbing={isGrabbing}
		>
			{children}
		</ScrollableElement>
	);
}

function TaskDeadlines({ years, taskId, showOnlyOverdue }) {
	const {
		data: { deadlineEntries } = { deadlineEntries: [] },
		loading,
		error,
	} = useQuery(GET_TASK_DEADLINE_ENTRIES, {
		variables: {
			taskId,
			isOverdue: showOnlyOverdue,
		},
	});

	if (error) {
		console.error(error);
	}

	if (loading) {
		return (
			<>
				{years.map(year =>
					months.map(month => (
						<Cell key={`${year}-${month.short}`}></Cell>
					))
				)}
			</>
		);
	}

	return (
		<>
			{years.map(year =>
				months.map((month, monthIndex) => {
					const currentDeadlineEntries =
						deadlineEntries?.filter(deadlineEntry => {
							const deadlineEntryDate = new Date(
								deadlineEntry.date
							);
							const deadlineEntryYear =
								deadlineEntryDate.getFullYear();
							const deadlineEntryMonth =
								deadlineEntryDate.getMonth();

							return (
								year === deadlineEntryYear &&
								monthIndex === deadlineEntryMonth
							);
						}) ?? [];

					return (
						<TimelineCell
							key={`${year}-${month.short}`}
							year={year}
							month={monthIndex}
						>
							{currentDeadlineEntries.map(deadlineEntry => (
								<TaskDeadlineEntry
									key={deadlineEntry._id}
									deadlineEntry={deadlineEntry}
								/>
							))}
						</TimelineCell>
					);
				})
			)}
		</>
	);
}

const TimelineCellWrapper = styled(Cell)`
	justify-content: center;
`;

function TimelineCell({ year, month, children }) {
	return (
		<TimelineCellWrapper
			$current={year === currentYear && month === currentMonth}
		>
			{children}
		</TimelineCellWrapper>
	);
}

function TaskDeadlineEntry({ deadlineEntry }) {
	const [showEntryDialog, setShowEntryDialog] = useState(false);

	return (
		<>
			<TaskDeadlineEntryIcon
				deadlineEntry={deadlineEntry}
				onClick={() => setShowEntryDialog(true)}
			/>

			<EditAssignmentEntryDialog
				deadlineEntry={deadlineEntry}
				open={showEntryDialog}
				onClose={() => setShowEntryDialog(false)}
			/>
		</>
	);
}

const TimelineEntryWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100%;
`;

const TimelineEntryButton = styled.button`
	background-color: transparent;
	border: 1px solid
		${({ $status, $hasCompletedSubTasks, $isOverdue }) =>
			assigmentTaskDeadlineEntryStatusColor({
				status: $status,
				hasCompletedSubTasks: $hasCompletedSubTasks,
				isOverdue: $isOverdue,
				paletteType: 'dark',
			})};
	border-radius: 3px;
	cursor: pointer;
	line-height: 1;
	opacity: ${p => (p.$notRelevant ? 0.3 : 1)};
	position: relative;
	width: 34px;
	padding: 0;
`;

const EntryMonth = styled.div`
	background: ${({ $status, $hasCompletedSubTasks, $isOverdue }) =>
		assigmentTaskDeadlineEntryStatusColor({
			status: $status,
			hasCompletedSubTasks: $hasCompletedSubTasks,
			isOverdue: $isOverdue,
			paletteType: 'dark',
		})};
	font-size: 9px;
	padding: 3px 0;
	text-align: center;
	text-transform: uppercase;
`;

const EntryDay = styled.div`
	font-size: 11px;
	padding: 2px 3px;
	text-align: center;
`;

export function TaskDeadlineEntryIcon({ deadlineEntry, onClick }) {
	const day = formatDate(deadlineEntry.date, 'DD');
	const month = formatDate(deadlineEntry.date, 'MMM');
	const status = deadlineEntry?.status;
	const isOverdue = deadlineEntry.isOverdue;
	const postponedStatus = deadlineEntry?.postponedStatus;
	const hasCompletedSubTasks = deadlineEntry.hasCompletedSubTasks;
	const notRelevant = deadlineEntry.status === 'Ikke aktuell';
	const files = deadlineEntry?.files ?? [];
	const comments = deadlineEntry?.comments ?? [];

	return (
		<TimelineEntryWrapper>
			<TimelineEntryButton
				$notRelevant={notRelevant}
				$status={status}
				$hasCompletedSubTasks={hasCompletedSubTasks}
				$isOverdue={isOverdue}
				onClick={onClick}
			>
				<TaskDeadlineEntryTooltip files={files} comments={comments}>
					<EntryMonth
						$status={status}
						$hasCompletedSubTasks={hasCompletedSubTasks}
						$isOverdue={isOverdue}
					>
						{month}
					</EntryMonth>

					<EntryDay>{day}</EntryDay>

					<DeadlineEntryStatusIcon
						status={status}
						hasCompletedSubTasks={hasCompletedSubTasks}
						isOverdue={isOverdue}
						postponedStatus={postponedStatus}
					/>
				</TaskDeadlineEntryTooltip>
			</TimelineEntryButton>
		</TimelineEntryWrapper>
	);
}

export function TaskDeadlineEntryTooltip({
	files = [],
	comments = [],
	children,
}) {
	return (
		<Tooltip
			title={<TooltipText files={files} comments={comments} />}
			placement="right"
			arrow
		>
			<div>{children}</div>
		</Tooltip>
	);
}

const TooltipTextWrapper = styled.div`
	font-size: 0.8rem;
`;

function TooltipText({ files, comments }) {
	return (
		<TooltipTextWrapper>
			{pluralize('Fil', 'Filer', files)}: {files.length}
			<br />
			{pluralize('Kommentar', 'Kommentarer', comments)}: {comments.length}
		</TooltipTextWrapper>
	);
}

const StatusIconWrapper = styled.div`
	background: ${p => p.theme.palette.common.white};
	color: ${({ $status, $hasCompletedSubTasks, $isOverdue }) =>
		assigmentTaskDeadlineEntryStatusColor({
			status: $status,
			hasCompletedSubTasks: $hasCompletedSubTasks,
			isOverdue: $isOverdue,
			paletteType: 'dark',
		})};
	border-radius: 50%;
	font-weight: bold;
	font-size: 20px;
	height: 22px;
	width: 22px;
	position: absolute;
	top: -10px;
	right: -13px;
`;

function DeadlineEntryStatusIcon({ status, isOverdue, postponedStatus }) {
	if (status === 'Kontrollert') {
		return (
			<StatusIconWrapper $status={status} $isOverdue={isOverdue}>
				<CheckIcon fontSize="inherit" />
			</StatusIconWrapper>
		);
	} else if (postponedStatus) {
		return (
			<StatusIconWrapper $status={status} $isOverdue={isOverdue}>
				<AccessTimeFilledIcon fontSize="inherit" />
			</StatusIconWrapper>
		);
	}

	return null;
}
