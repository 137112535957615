import { useEffect } from 'react';
import styled from 'styled-components';
import { useApolloClient, useMutation } from '@apollo/client';
import { Link } from 'react-router';

import { download } from 'utils/fileHandling';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import {
	DocumentTitleWrapper,
	DocumentTitle,
	DocumentPath,
} from 'components/DocumentLayout';

import Card from 'components/Card';
import Icon from 'components/Icon';
import DocumentIcon from 'components/DocumentIcon';
import ErrorAlert from 'components/ErrorAlert';

import Signers from 'web/components/Signers';
import History from 'web/components/History';

import {
	CLEAR_RECENT_DOCUMENTS,
	REQUEST_DOCUMENT_DOWNLOAD_URL,
	REQUEST_SIGNED_DOCUMENT_DOWNLOAD_URL,
} from 'api/mutations/documentMutations';
import { CANCEL_ALL_SIGNATURE_REQUESTS } from 'api/mutations/signatureMutations';

const DocumentButtonIcon = styled.span`
	margin-right: 8px;
	float: left;
	text-align: right;
`;
const StyledLink = styled(Link)`
	border: none;
	outline: none;
	background: none;
	width: 100%;
	text-align: left;
	padding: 15px 0;
	display: block;
	color: inherit;
	cursor: pointer;
	text-decoration: none;
`;
const StyledButton = styled.button`
	border: none;
	outline: none;
	background: none;
	width: 100%;
	text-align: left;
	padding: 15px 0;
	display: block;
	color: inherit;
	cursor: pointer;
	font-size: inherit;
	font-family: inherit;
`;

const ManuallySigned = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 15px 0;
	line-height: 1;

	i {
		color: ${p => p.theme.palette.status.success};
	}
`;

export default function Document({ documentNode }) {
	const apolloClient = useApolloClient();
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();

	const [markAsViewed] = useMutation(CLEAR_RECENT_DOCUMENTS);

	const [
		cancelAllSignatureRequests,
		{
			loading: cancelAllSignatureRequestsLoading,
			error: cancelAllSignatureRequestsError,
		},
	] = useMutation(CANCEL_ALL_SIGNATURE_REQUESTS, {
		refetchQueries: ['paginateDocumentNodes'],
	});

	useEffect(() => {
		if (!documentNode || documentNode.type === 'folder') return;

		markAsViewed({ variables: { ids: [documentNode._id] } });
	}, [documentNode._id]);

	return (
		<Card
			icon={
				<DocumentIcon
					needsSignature={documentNode.isSigning}
					hasSignature={documentNode.isSigningComplete}
					size="2xl"
				/>
			}
		>
			<DocumentTitleWrapper>
				<DocumentTitle>{documentNode.name}</DocumentTitle>

				<DocumentPath>{documentNode.path}</DocumentPath>
			</DocumentTitleWrapper>

			<ErrorAlert error={cancelAllSignatureRequestsError} />

			{!documentNode.isHidden && (
				<>
					<StyledLink to="rediger">
						<DocumentButtonIcon>
							<Icon icon="edit" />
						</DocumentButtonIcon>
						Rediger dokument
					</StyledLink>

					{!documentNode.isSigning &&
						documentNode?.contentType?.includes('pdf') && (
							<StyledLink to="signer">
								<DocumentButtonIcon>
									<Icon icon="file" />
								</DocumentButtonIcon>
								Send til signering
							</StyledLink>
						)}

					<StyledLink to="del">
						<DocumentButtonIcon>
							<Icon icon="share-alt" />
						</DocumentButtonIcon>

						<span>Del med noen</span>
					</StyledLink>
				</>
			)}

			<StyledButton
				onClick={async () => {
					try {
						const response = await apolloClient.mutate({
							mutation: REQUEST_DOCUMENT_DOWNLOAD_URL,
							variables: {
								documentId: documentNode._id,
							},
						});

						download(
							response.data.item.url,
							response.data.item.name
						);
					} catch (error) {
						console.error(error);
					}
				}}
			>
				<DocumentButtonIcon>
					<Icon icon="file-download" />
				</DocumentButtonIcon>
				Last ned dokument
			</StyledButton>

			{documentNode.isSigning && !documentNode.isSigningComplete && (
				<StyledButton
					disabled={cancelAllSignatureRequestsLoading}
					onClick={async () => {
						try {
							if (
								!(await verify({
									title: 'Kansellere alle signeringer',
									text: 'Er du sikker på at du vil kansellere signaturforespørselen?',
								}))
							) {
								return;
							}

							const { data } = await cancelAllSignatureRequests({
								variables: {
									documentId: documentNode._id,
								},
							});

							if (data?.canceled) {
								notify('Alle signeringer ble kansellert');
							}
						} catch (err) {
							console.error(err);
						}
					}}
				>
					<DocumentButtonIcon>
						<Icon icon="ban" />
					</DocumentButtonIcon>
					Kansellere signeringer
				</StyledButton>
			)}

			{documentNode.signedPDFGenerated && (
				<StyledButton
					onClick={async () => {
						try {
							const response = await apolloClient.mutate({
								mutation: REQUEST_SIGNED_DOCUMENT_DOWNLOAD_URL,
								variables: {
									documentId: documentNode._id,
								},
							});

							download(
								response.data.item.url,
								response.data.item.name
							);
						} catch (error) {
							console.error(error);
						}
					}}
				>
					<DocumentButtonIcon>
						<Icon icon="file-download" />
					</DocumentButtonIcon>
					Last ned signert dokument
				</StyledButton>
			)}

			{documentNode.manuallySigned && (
				<ManuallySigned>
					<Icon icon="file-check" />
					Dokumentet er signert manuelt
				</ManuallySigned>
			)}

			{!documentNode.manuallySigned && documentNode.isSigning && (
				<Signers
					documentId={documentNode._id}
					title="Mottakere av signering"
				/>
			)}

			<History history={documentNode.history} />
		</Card>
	);
}
