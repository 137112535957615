import { Fragment, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { getRoleLabel } from 'server/libs/companyRoles';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';
import { Card, CardContent } from 'components/GridCardLayout';

import { ADD_PROFF_DATA_DEVIATION_EXCEPTION } from 'api/mutations/tenantMutations';
import {
	InfoSection,
	InfoTitle,
	InfoLineWrapper,
	InfoLine,
	InfoValue,
	Button,
} from 'web/components/panels/PanelItems';
import UpdateProffDataButton from './company/UpdateProffDataButton';
import CompanyRolesAutocomplete from 'web/components/inputs/CompanyRolesAutocomplete';
import UsersCreateDialog from 'web/screens/dialogs/users/UsersCreateDialog';
import AddOwnerDialog from './panels/dialogs/AddOwnerDialog';

const Wrapper = styled.div`
	margin-bottom: 20px;
`;

const StyledList = styled(List)`
	padding: 0;
	margin: 0;

	a {
		color: inherit;
	}
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 16px;
	padding: 0 0 32px;

	@media (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: 1600px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

const ErrorTextMessage = styled.span`
	color: ${p => p.theme.palette.status.error};
	font-weight: 700;
`;

const AddUserButton = styled.button`
	padding: 0;
	border: none;
	background: none;
	cursor: pointer;
	transition: color 150ms;

	&:hover {
		color: ${p => p.theme.palette.primary.main};
	}
`;

const ToLower = styled.span`
	text-transform: lowercase;
`;

export default function ProffDataDeviations({ companyId, deviations }) {
	if (!deviations.hasDeviations) {
		return (
			<Wrapper>
				<Card>
					<CardContent>Bedriften har ingen avvik!</CardContent>
				</Card>
			</Wrapper>
		);
	}

	return (
		<>
			{deviations.missingProffData && (
				<ErrorMessage
					title="Mangler data fra Proff!"
					errors={
						'Data fra proff.no mangler! Klikk på knappen for å hente siste data fra proff.no.'
					}
				/>
			)}

			<Grid>
				{deviations.oldProffData && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>Utdatert data fra Proff</InfoTitle>

								<div>
									Data fra proff.no har ikke blitt på over 6
									måneder.
									<UpdateProffDataButton
										companyId={companyId}
										hasProffData={true}
									/>
								</div>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.companyName && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Navn er registrert i Proff med:
								</InfoTitle>

								<div>{deviations.companyName}</div>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.companyAuditorsNotInProff && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Revisorfirma ikke registert i Proff:
								</InfoTitle>
								<StyledList>
									{deviations.companyAuditorsNotInProff.map(
										auditor => (
											<Fragment
												key={`company-auditor-${auditor._id}`}
											>
												<ListItem>
													<CompanyAuditorsNotInProff
														companyId={companyId}
														auditor={auditor}
													/>
												</ListItem>
												<Divider component="li" />
											</Fragment>
										)
									)}
								</StyledList>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.proffUsersNotInCompany && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Personer registrert i Proff:
								</InfoTitle>

								<StyledList>
									{deviations.proffUsersNotInCompany.map(
										user => (
											<Fragment
												key={`proff-user-${user.personId}-${user.role}`}
											>
												<ListItem>
													<ProffUsersNotInCompany
														companyId={companyId}
														user={user}
													/>
												</ListItem>
												<Divider component="li" />
											</Fragment>
										)
									)}
								</StyledList>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.companyUsersNotInProff && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Personer ikke registrert i Proff:
								</InfoTitle>

								<StyledList>
									{deviations.companyUsersNotInProff.map(
										user => (
											<Fragment
												key={`company-user-${user._id}-${user.role}`}
											>
												<ListItem>
													<CompanyUsersNotInProff
														companyId={companyId}
														user={user}
													/>
												</ListItem>
												<Divider component="li" />
											</Fragment>
										)
									)}
								</StyledList>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.proffOwnersNotInCompany && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Reelle rettighetshavere registrert i Proff:
								</InfoTitle>

								<StyledList>
									{deviations.proffOwnersNotInCompany.map(
										user => (
											<Fragment
												key={`proff-owner-${user.name}`}
											>
												<ListItem>
													<ProffOwnersNotInCompany
														user={user}
														companyId={companyId}
													/>
												</ListItem>
												<Divider component="li" />
											</Fragment>
										)
									)}
								</StyledList>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.companyOwnersNotInProff && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Reelle rettighetshavere ikke registrert i
									Proff:
								</InfoTitle>

								<StyledList>
									{deviations.companyOwnersNotInProff.map(
										user => (
											<Fragment
												key={`company-owner-${user._id}`}
											>
												<ListItem>
													<CompanyOwnerNotInProff
														companyId={companyId}
														user={user}
													/>
												</ListItem>
												<Divider component="li" />
											</Fragment>
										)
									)}
								</StyledList>
							</CardContent>
						</Card>
					</InfoSection>
				)}
			</Grid>
		</>
	);
}

function CompanyAuditorsNotInProff({ companyId, auditor }) {
	return (
		<InfoLineWrapper>
			<InfoLine>
				<PersonIcon />

				<InfoValue>
					<div>
						{auditor?.auditingCompany?.name ? (
							<span>
								{auditor.name} ({auditor.auditingCompany.name})
								skal ikke være revisor.
							</span>
						) : (
							<span>
								{auditor.name} mangler tilknyttet revisorfirma
							</span>
						)}
					</div>
				</InfoValue>
			</InfoLine>
			<ArchiveButton
				companyId={companyId}
				exception={{
					type: 'COMPANY_AUDITOR_NOT_IN_PROFF',
					name: auditor.name,
					_id: auditor._id,
				}}
			/>
		</InfoLineWrapper>
	);
}

function ProffUsersNotInCompany({ companyId, user }) {
	return (
		<>
			<InfoLineWrapper>
				<InfoLine>
					{user.isRegistered ? (
						<PersonIcon />
					) : (
						<CreateUserButton companyId={companyId} user={user} />
					)}

					<InfoValue>
						<div>
							{user.name} skal være{' '}
							<ToLower>{user.title}</ToLower>.
						</div>
					</InfoValue>
				</InfoLine>

				<ArchiveButton
					companyId={companyId}
					exception={{
						type: 'PROFF_USER_NOT_IN_COMPANY',
						name: user.name,
						role: user.role,
					}}
				/>
			</InfoLineWrapper>
		</>
	);
}

function CompanyUsersNotInProff({ companyId, user }) {
	return (
		<InfoLineWrapper>
			<InfoLine>
				<PersonIcon />

				<InfoValue>
					<div>
						{user.name} skal ikke være{' '}
						<ToLower>{getRoleLabel(user.role)}</ToLower>.
					</div>
				</InfoValue>
			</InfoLine>

			<ArchiveButton
				companyId={companyId}
				exception={{
					type: 'COMPANY_USER_NOT_IN_PROFF',
					name: user.name,
					role: user.role,
				}}
			/>
		</InfoLineWrapper>
	);
}

function ProffOwnersNotInCompany({ companyId, user }) {
	return (
		<InfoLineWrapper>
			<InfoLine>
				{user.isRegistered ? (
					<PersonIcon />
				) : (
					<AddOwnerButton companyId={companyId} user={user} />
				)}

				<InfoValue>
					<div>{user.name} skal være rettighetshaver.</div>
				</InfoValue>
			</InfoLine>

			<ArchiveButton
				companyId={companyId}
				exception={{
					type: 'PROFF_OWNER_NOT_IN_COMPANY',
					name: user.name,
				}}
			/>
		</InfoLineWrapper>
	);
}

function CompanyOwnerNotInProff({ companyId, user }) {
	return (
		<InfoLineWrapper>
			<InfoLine>
				<PersonIcon />

				<InfoValue>
					<div>{user.name} skal ikke være rettighetshaver.</div>
				</InfoValue>
			</InfoLine>

			<ArchiveButton
				companyId={companyId}
				exception={{
					type: 'COMPANY_OWNER_NOT_IN_PROFF',
					name: user.name,
				}}
			/>
		</InfoLineWrapper>
	);
}

function ArchiveButton({ companyId, exception }) {
	const { notify } = useSnackbar();

	const [addProffDataDeviation, { loading, error }] = useMutation(
		ADD_PROFF_DATA_DEVIATION_EXCEPTION,
		{
			refetchQueries: ['findOneTenant'],
		}
	);

	const handleClick = async () => {
		const {
			data: { archived },
		} = await addProffDataDeviation({
			variables: {
				_id: companyId,
				exception,
			},
		});

		if (archived) {
			notify('Avviket ble arkivert!');
		}
	};

	return (
		<>
			<Button disabled={loading} onClick={handleClick}>
				Arkiver
			</Button>

			{error && <ErrorTextMessage>{error.toString()}</ErrorTextMessage>}
		</>
	);
}

function CreateUserButton({ companyId, user }) {
	const [openDialog, setOpenDialog] = useState(false);

	return (
		<>
			<AddUserButton onClick={() => setOpenDialog(true)}>
				<PersonAddIcon />
			</AddUserButton>

			<UsersCreateDialog
				companyId={companyId}
				user={user}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				onSubmitSuccess={() => setOpenDialog(false)}
				extraFormFields={
					<CompanyRolesField
						companyId={companyId}
						role={{
							value: user.role,
							title: user.title,
						}}
					/>
				}
			/>
		</>
	);
}

function CompanyRolesField({ companyId, role }) {
	const {
		setValue,
		register,
		clearErrors,
		formState: { errors },
	} = useFormContext();

	// Registering field this way, because we want to set value manually to control the format
	register('userRoles', {
		value: [{ role: role.value, id: companyId }],
		required: true,
	});

	return (
		<>
			<CompanyRolesAutocomplete
				defaultValue={[
					{
						label: role.title,
						value: role.value,
					},
				]}
				onChange={roles => {
					if (!roles || roles.length === 0) {
						setValue('userRoles', null);
						return;
					}

					if (errors.userRoles) {
						clearErrors('userRoles');
					}

					setValue(
						'userRoles',
						roles.map(role => {
							return {
								role: role.value,
								id: companyId,
							};
						})
					);
				}}
				hasError={!!errors.userRoles}
			/>
		</>
	);
}

function AddOwnerButton({ companyId, user }) {
	const [openDialog, setOpenDialog] = useState(false);

	return (
		<>
			<AddUserButton onClick={() => setOpenDialog(true)}>
				<PersonAddIcon />
			</AddUserButton>

			<AddOwnerDialog
				companyId={companyId}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				onlyCreatUser={true}
				user={user}
			/>
		</>
	);
}
